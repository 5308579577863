<template>
  <CCard>
    <CCardHeader>
      <h3>Ver Grupos de Atributos</h3>
    </CCardHeader>
    <CCardBody>
      <div class="form-group">
        <label>Grupo:</label>
        <p>{{ registro.grupo }}</p>
      </div>
      <div class="form-group">
        <label>Tipo:</label>
        <p>{{ registro.tipo }}</p>
      </div>
      <div class="form-group">
        <label>Idioma:</label>
        <p>{{ registro.idioma }}</p>
      </div>
      <div class="form-group">
        <label>Categoria:</label>
        <p>{{ registro.categoria }}</p>
      </div>

      <div class="form-group">
        <router-link to="/ecoAtributosGrupos/List">
          <CButton color="secondary">
            <i class="cil-arrow-circle-left"></i>
            Regresar
          </CButton>
        </router-link>
      </div>

    </CCardBody>

  </CCard>

</template>
<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        grupo: '',
        tipo: '',
        created_at: '',
        updated_at: '',
        delete_at: '',
        textura: '',
        orden: '',
        color: '',
        orden: '',
        codigo: '',
        idioma: '',

      }
    }
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== '0') {
        let url = 'eco-atributos-grupos/' + this.id
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];

      }
    },

  },

  mounted() {
    this.getRegistro();
  }

}
</script>